import React, { useEffect, useState, useContext, useRef } from "react";
import "./ConversationMessages.css";
import Message from "./Message";
import { ConversationContext } from "../../../store/context/ConversationContext";

import InfiniteScroll from "./InfiniteScroll";
import ScheduledMessageSavedModal from "./ScheduledMessageSavedModal";

import Select from "react-select";
import Table from "react-bootstrap/Table";
import Picker from "emoji-picker-react";
import EmojiIcon from "../../../images/smile.png";
import Searchbar from "../../Reusable/SearchbarApi/Searchbar";
import RectangleButton from "../../Reusable/RectangleButton/RectangleButton";
import ClearButton from "../../Reusable/ClearButton/ClearButton";

import TimePicker from "react-times";

const initialState = {
  name: 'No file chosen',
  value: ''
};

const ConversationMessages = (props) => {
  const { state, actions } = useContext(ConversationContext);
  const [showWhatsupTemplates, setShowWhatsupTemplates] = useState(false);
  const [showAgentsTemplates, setShowAgentsTemplates] = useState(false);
  const [message, setMessage] = useState("");
  const [charcount, setCharCount] = useState("0");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [showEmoji, setShowEmoji] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [messageTemplates, setMessageTemplates] = useState([]);
  const [agentTemplates, setAgentTemplates] = useState([]);
  const [user] = useState(JSON.parse(localStorage.getItem("access_token")));
  const [selectedFile, setSelectedFile] = useState(initialState);
  const [scheduleMessage, setScheduleMessage] = useState(false);
  const [timeScheduledMessage, setTimeScheduledMessage] = useState('09:00')
  const [showSavedModal, setShowSavedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState(null);

  const hasMoreData = state.currentMessagePage < state.currentConversation.messages_pages;
  const maxchar = 4000;

  const messageTemplatesSearchUrl =
    process.env.REACT_APP_BASEURL +
    "/api/conversations/message-templates/" +
    state.currentConversation.branch_id +
    '/' +
    state.currentConversation.company_channel_id +
    "/search";
  const messageTemplatesUrl =
    process.env.REACT_APP_BASEURL +
    "/api/conversations/message-templates/" +
    state.currentConversation.branch_id +
    '/' +
    state.currentConversation.company_channel_idl

  const agentTemplatesSearchUrl =
    process.env.REACT_APP_BASEURL +
    "/api/conversations/agent-templates/" +
    state.currentConversation.branch_id +
    "/search";
  const agentTemplatesUrl =
    process.env.REACT_APP_BASEURL +
    "/api/conversations/agent-templates/" +
    state.currentConversation.branch_id;

  useEffect(() => {
    let itinerariesForSelect = [];
    if (typeof state.currentConversation.itineraries != 'undefined') {
      state.currentConversation.itineraries.map(itinerary => {
        itinerariesForSelect.push({ value: itinerary.id, label: itinerary.dossier_id });
        return true;
      });
    }

    return () => {
      setScheduleMessage(false)
      setMessage("");
      setSelectedFile(initialState);
      setSelectedTemplate(null);
      setShowAgentsTemplates(false);
      setShowWhatsupTemplates(false);
    }
  }, [state.currentConversation.id]);

  useEffect(() => {
    setMessageTemplates(state.messageTemplates);
    setAgentTemplates(state.agentTemplates)
  }, [state.currentConversation, showWhatsupTemplates, showAgentsTemplates]);

  useEffect(() => {
    setMessage("");
  }, [state.currentConversation.id])

  if (state.conversations?.filtered?.open) {
    actions.updateFavicon();
  }

  function handleChange(event) {
    if (event.target.value.charAt(0) === "/") {
      setShowAgentsTemplates(false);
      setShowWhatsupTemplates(true);
    } else {
      setShowWhatsupTemplates(false);
    }

    if (event.target.value.charAt(0) === "\\") {
      setShowWhatsupTemplates(false);
      setShowAgentsTemplates(true);
    } else {
      setShowAgentsTemplates(false);
    }
    setMessage(event.target.value);
    setCharCount(event.target.value.length);
  }

  function onClickTemplate(agentsTemplate, messageType) {
    setMessage(agentsTemplate.body);
    setSelectedTemplate(agentsTemplate);
    setMessageType(messageType);
    setShowWhatsupTemplates(false);
    setShowAgentsTemplates(false);
  }

  function showEmojis() {
    setShowEmoji(!showEmoji);
    setShowMessage(!showMessage);
  }

  const onEmojiClick = (event, emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleSubmit = (evt) => {
    if (!scheduleMessage) {
      evt.preventDefault();
      let date = new Date();

      var formData = new FormData();

      if (message !== "" || selectedFile.size > 0) {
        formData.append('author_id', user.id);
        formData.append('owner_type', 'Agent');
        formData.append('conversation_id', state.currentConversation.id);
        formData.append('message', message)
        formData.append('datetime', date.getTime())
        formData.append('message_type', messageType)

        if (selectedTemplate?.id) {
          formData.append('template_id', selectedTemplate.id);
        }
        if (selectedFile.type) {
          formData.append('file', selectedFile)
        }
        actions.sendMessage(formData);

        setMessage("");
        setCharCount(0);
        setMessageType(null);
        setSelectedFile(initialState);
        setSelectedTemplate(null);
      }
    } else {
      evt.preventDefault();

      var formData = new FormData();
      let date = new Date();
      if (message !== "" && timeScheduledMessage !== null) {
        formData.append('conversation_id', state.currentConversation.id);
        formData.append('message', message)
        let dateString = date.toLocaleDateString('en-US');
        let sendAt = dateString + " " + timeScheduledMessage;

        if (Date.parse(sendAt) > Date.now()) {
          formData.append('send_at', sendAt)

          actions.createScheduledMessage(formData, setShowSavedModal)

          // setShowSavedModal(true);
          setMessage("");
          setSelectedFile(initialState);
          setSelectedTemplate(null);
        } else {
          actions.setError("Selected time is in the past")
        }
      } else {
        actions.setError("Select a time to send the scheduled message")
      }
    }
  };

  const messageTemplatesCallback = (data) => {
    setMessageTemplates(data);
  };

  const agentTemplatesCallback = (data) => {
    setAgentTemplates(data);
  };

  function deleteMessage(id) {
    actions.deleteMessage(id);
  }

  function tagMessage(id, tag, itinerary, isTemplate) {
    actions.tagMessage(id, tag, itinerary, isTemplate);
  }

  function markAsUnread(id) {
    actions.markAsUnread(id);
  }

  function sendTo(userId, messageId) {
    actions.sendTo((userId, messageId));
  }

  function setReaction(reactionId, messageId) {
    actions.setReaction(reactionId, messageId);
  }

  function translateMessage(languageId, messageId) {
    actions.translateMessage(languageId, messageId);
  }

  const onClickOpenTemplates = () => {
    setShowWhatsupTemplates(true);
  };

  const onClickCloseTemplates = () => {
    setShowWhatsupTemplates(false);

  };

  const onSavedModalClose = () => {
    setShowSavedModal(false);
  }

  const onFileChosen = (selectorFiles) => {
    if (selectorFiles[0].type === "image/png"
      || selectorFiles[0].type === "image/jpg"
      || selectorFiles[0].type === "image/jpeg"
      || selectorFiles[0].type === "image/gif"
      || selectorFiles[0].type === "video/mp4"
      || selectorFiles[0].type === "audio/mpeg"
      || selectorFiles[0].type === "application/pdf") {

      //Max size is 20MB (so 20,000,000 bytes)
      if (selectorFiles[0].size > 20000000) {
        actions.setError("File is too large. Can not be larger than 20MB.")
      } else {
        setSelectedFile(selectorFiles[0])
      }
    } else {
      actions.setError("Wrong file type. Can only be a png, jpg, jpeg, gif, mp3, mp4 or pdf")
    }
  }

  const onClickScheduleMessage = () => {
    if (scheduleMessage) {
      setScheduleMessage(false)
    } else {
      setScheduleMessage(true)
    }
  }

  const onChangeTime = (value) => {
    setTimeScheduledMessage(`${value.hour}:${value.minute}`);
  };

  //Setting state of loading, so if the agent is scrolling a lot, the first request has to be finished before the second can be fired off
  const loadMoreMessages = (ref) => {
    setLoading(true);
    actions.fetchMoreMessages(state.currentConversation.id, ref);
    setLoading(false);
  };

  return (
    <div>
      <div>
        <div className="traveler_name text-center">
          <p>{state.currentConversation.traveler.full_name}</p>
        </div>
        <InfiniteScroll
          hasMoreData={hasMoreData}
          isLoading={loading}
          onTopHit={loadMoreMessages}
          loadOnMount={true}
        >
          <div
            className="conversation-details-open col-sm-12"
            id="chat"
          >
            {state.currentConversation.messages.length > 0 ? (
              <>
              {
                state.currentConversation.messages.map((message, i) => (
                  <Message
                    key={i}
                    i={i}
                    message={message}
                    deleteAction={deleteMessage}
                    tagMessageAction={tagMessage}
                    markAsUnreadAction={markAsUnread}
                    sendToAction={sendTo}
                    setReactionAction={setReaction}
                    translateMessageAction={translateMessage}
                  />
                ))
              }
              </>
            ) : null}
          </div>

        </InfiniteScroll>
        <div>
          <form onSubmit={handleSubmit}>
            {showWhatsupTemplates ? (
              <div className="conversation-input-container templates">
                <div className="conversation__templates">
                  <Searchbar
                    id="whatsapp-template-searchbar"
                    searchUrl={messageTemplatesSearchUrl}
                    allDataUrl={messageTemplatesUrl}
                    callback={messageTemplatesCallback}
                  />
                  <ClearButton onClick={onClickCloseTemplates} />
                </div>

                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  {messageTemplates !== null ? (
                    <tbody>
                      {messageTemplates.map((whatsupTemplate) => (
                        <tr
                          key={whatsupTemplate.id}

                          onClick={() => onClickTemplate(whatsupTemplate, 'regular')}
                        >
                          <td>{whatsupTemplate.title}</td>
                          <td>{whatsupTemplate.body.substr(0, 80) + "..."}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : null}
                </Table>
              </div>
            ) : null}

            {showAgentsTemplates ? (
              <div className="conversation-input-container conversation-templates">
                <Searchbar
                  id="whatsapp-template-searchbar"
                  className="conversation-search"
                  searchUrl={agentTemplatesSearchUrl}
                  allDataUrl={agentTemplatesUrl}
                  callback={agentTemplatesCallback}
                />
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Label</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  {agentTemplates !== null ? (
                    <tbody>
                      {agentTemplates.map((agentsTemplate) => (
                        <tr
                          key={agentsTemplate.id}
                          onClick={() => onClickTemplate(agentsTemplate, 'agent')}
                        >
                          <td>{agentsTemplate.title}</td>
                          <td>{agentsTemplate.body.substr(0, 80) + "..."}</td>
                        </tr>
                      ))}
                    </tbody>
                  ) : null}
                </Table>
              </div>
            ) : null}

            <div className="conversation-input-container">
              <div className="write-message-menu">
                <span onClick={showEmojis}>
                  <img className="emoji-icon" src={EmojiIcon} alt="icon" />
                </span>

                {!scheduleMessage &&
                  <>
                    <input type="file" id="actual-btn" name="file" hidden onChange={(e) => onFileChosen(e.target.files)} value={initialState.value} />
                    <label className="conversation-input-container__label-file-upload" htmlFor="actual-btn">Choose File</label>
                    <span id="file-chosen">{selectedFile.name}</span>
                  </>
                }

                {state.currentConversation.last_msg_24_hours ? (
                  <>
                    {scheduleMessage ? (
                      <>
                        <RectangleButton
                          className="schedule-msg__btn"
                          label="Write message"
                          onClick={onClickScheduleMessage}
                          type="button"
                        />
                      </>
                    ) : (
                      <>
                        <RectangleButton
                          className="schedule-msg__btn"
                          label="Schedule message"
                          isHighlight
                          onClick={onClickScheduleMessage}
                          type="button"
                        />
                      </>
                    )}
                  </>
                ) : null}
              </div>

            </div>
            {showEmoji ? (
              <Picker
                onEmojiClick={onEmojiClick}
                disableSkinTonePicker={true}
              />
            ) : null}

            {showMessage && !scheduleMessage ? (
              <div className="conversation-input-container mt-0">
                {state.currentConversation.last_msg_24_hours ||
                  selectedTemplate?.id ? (
                  <>
                    <textarea
                      placeholder={`Write message to ${state.currentConversation.traveler.full_name}`}
                      name="message"
                      id="current_message"
                      onChange={handleChange}
                      value={message}
                    />

                    <p style={{textAlign: 'right',  color: charcount > maxchar ? 'red' : null} }>{charcount} / {maxchar}</p>
                    {!scheduleMessage &&
                      <input
                        className="conversation-input-field-btn"
                        type="submit"
                        value="Send"
                      />
                    }
                  </>
                ) : (
                  <>
                    <div className="conversation-message__message">
                      {`Please select a template for ${state.currentConversation.traveler.full_name}`}
                    </div>
                    <RectangleButton
                      label="Template"
                      isSmall
                      onClick={onClickOpenTemplates}
                      type="button"
                    />
                  </>
                )}
              </div>
            ) : null}

            {scheduleMessage ? (
              <div className="conversation-input-container mt-0">
                <textarea
                  placeholder={`Write message to ${state.currentConversation.traveler.full_name}`}
                  name="message"
                  id="current_message"
                  onChange={handleChange}
                  value={message}
                />
                <p style={{textAlign: 'right',  color: charcount > maxchar ? 'red' : null} }>{charcount} / {maxchar}</p>

                <div className="conversation-scheduled-msg-input">
                  <input
                    className="conversation-input-field-btn save-scheduled-msg-btn"
                    type="submit"
                    value="Save"
                  />
                  <div className="template-day__time">
                    <TimePicker
                      isRequired
                      onTimeChange={(value) => onChangeTime(value)}
                      time={timeScheduledMessage || "09:00"}
                    />
                  </div>
                </div>

                <ScheduledMessageSavedModal show={showSavedModal} onHide={onSavedModalClose} />

              </div>
            ) : null}

          </form>
        </div>
      </div>
    </div >
  );
};

export default ConversationMessages;
